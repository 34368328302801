<template>
    <footer>
        <div class="cont row">
            <div class="title">
                {{ $t('message.footer_title') }}
            </div>

            <div class="socials">
                <a href="https://github.com/bro-n-bro" target="_blank" rel="noopener nofollow">
                    <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M37 0C16.5575 0 0 16.5575 0 37C0 53.3725 10.5912 67.2013 25.2987 72.1038C27.1487 72.4275 27.8425 71.3175 27.8425 70.3463C27.8425 69.4675 27.7962 66.5538 27.7962 63.455C18.5 65.1663 16.095 61.1888 15.355 59.1075C14.9387 58.0438 13.135 54.76 11.5625 53.8813C10.2675 53.1875 8.4175 51.4763 11.5162 51.43C14.43 51.3838 16.5112 54.1125 17.205 55.2225C20.535 60.8188 25.8537 59.2463 27.9812 58.275C28.305 55.87 29.2762 54.2513 30.34 53.3263C22.1075 52.4013 13.505 49.21 13.505 35.0575C13.505 31.0338 14.9388 27.7038 17.2975 25.1138C16.9275 24.1888 15.6325 20.3963 17.6675 15.3088C17.6675 15.3088 20.7662 14.3375 27.8425 19.1013C30.8025 18.2688 33.9475 17.8525 37.0925 17.8525C40.2375 17.8525 43.3825 18.2688 46.3425 19.1013C53.4187 14.2913 56.5175 15.3088 56.5175 15.3088C58.5525 20.3963 57.2575 24.1888 56.8875 25.1138C59.2463 27.7038 60.68 30.9875 60.68 35.0575C60.68 49.2563 52.0312 52.4013 43.7987 53.3263C45.14 54.4825 46.2962 56.7025 46.2962 60.1712C46.2962 65.12 46.25 69.0975 46.25 70.3463C46.25 71.3175 46.9437 72.4738 48.7937 72.1038C56.1388 69.6239 62.5213 64.9032 67.043 58.606C71.5646 52.3089 73.9978 44.7524 74 37C74 16.5575 57.4425 0 37 0Z" fill="currentColor"/></svg>
                </a>

                <a href="https://t.me/bro_n_bro_community" target="_blank" rel="noopener nofollow">
                    <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M74 37C74 57.4332 57.4332 74 37 74C16.5667 74 0 57.4332 0 37C0 16.5667 16.5667 0 37 0C57.4332 0 74 16.5667 74 37ZM38.3258 27.3153C34.7276 28.8107 27.5342 31.9094 16.7487 36.6084C14.9973 37.3052 14.0785 37.9867 13.9952 38.6527C13.8534 39.7812 15.2656 40.2252 17.1834 40.8264C17.4455 40.9097 17.7168 40.9929 17.9943 41.0854C19.8844 41.699 22.4251 42.4174 23.7448 42.4452C24.9442 42.4698 26.2823 41.9765 27.7593 40.9652C37.8356 34.1603 43.0372 30.7223 43.364 30.6483C43.5952 30.5959 43.9159 30.5281 44.1317 30.7223C44.3476 30.9135 44.326 31.2773 44.3044 31.376C44.1626 31.9711 38.6311 37.1172 35.7636 39.7812C34.8694 40.6106 34.2373 41.1995 34.1078 41.3352C33.818 41.6343 33.522 41.921 33.2383 42.1954C31.4808 43.8851 30.1673 45.1554 33.3123 47.2274C34.8232 48.2233 36.0318 49.0466 37.2374 49.8667C38.554 50.764 39.8675 51.6582 41.5695 52.7743C42.0012 53.058 42.4143 53.3509 42.8182 53.6377C44.3507 54.7322 45.7289 55.7127 47.4309 55.5586C48.4176 55.4661 49.4412 54.538 49.9592 51.7661C51.1833 45.2109 53.5945 31.0152 54.1526 25.1631C54.1865 24.6772 54.1659 24.189 54.0909 23.7078C54.0461 23.319 53.8568 22.9614 53.5606 22.7057C53.1197 22.3449 52.4352 22.2678 52.1268 22.274C50.7362 22.2987 48.6026 23.0417 38.3258 27.3153Z" fill="currentColor"/></svg>
                </a>

                <a href="https://twitter.com/Bro_n_Bro" target="_blank" rel="noopener nofollow">
                    <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M37 0C16.5674 0 0 16.5674 0 37C0 57.4326 16.5674 74 37 74C57.4326 74 74 57.4326 74 37C74 16.5674 57.4326 0 37 0ZM54.7815 27.8904C54.8062 28.2786 54.8062 28.6833 54.8062 29.0797C54.8062 41.2038 45.5728 55.1696 28.6998 55.1696C23.4967 55.1696 18.6734 53.6583 14.61 51.0567C15.3533 51.1393 16.0636 51.1723 16.8234 51.1723C21.1181 51.1723 25.0658 49.7188 28.2125 47.2576C24.1821 47.175 20.796 44.5321 19.6397 40.8982C21.052 41.1047 22.3239 41.1047 23.7775 40.733C21.7022 40.3114 19.8369 39.1843 18.4985 37.5433C17.1601 35.9022 16.431 33.8484 16.4353 31.7308V31.6152C17.6493 32.3007 19.0781 32.7219 20.573 32.7797C19.3163 31.9422 18.2857 30.8075 17.5726 29.4764C16.8595 28.1452 16.4858 26.6586 16.4848 25.1484C16.4848 23.4388 16.9308 21.8779 17.7319 20.5234C20.0354 23.359 22.9097 25.6782 26.1681 27.3302C29.4266 28.9821 32.9961 29.93 36.6449 30.1121C35.3482 23.8766 40.0063 18.8304 45.6058 18.8304C48.2487 18.8304 50.6272 19.9371 52.3038 21.721C54.3768 21.3328 56.3589 20.5565 58.1263 19.5158C57.4408 21.6384 56.0038 23.4306 54.096 24.5621C55.946 24.3638 57.7299 23.8518 59.3817 23.1333C58.1346 24.9667 56.5737 26.5938 54.7815 27.8904Z" fill="currentColor"/></svg>
                </a>

                <a href="https://keybase.io/bronbroio" target="_blank" rel="noopener nofollow">
                    <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="37" cy="37" r="37" fill="currentColor"/><path fill-rule="evenodd" clip-rule="evenodd" d="M29.3749 30.4251C28.6796 29.0397 28.4076 27.4285 28.5847 25.7057C23.9967 25.7448 23.5269 23.877 23.449 22.0334L23.614 19.3444C23.7221 17.5834 25.2777 16.2071 27.0254 16.2071C30.7113 16.3526 31.0595 16.4405 32.0813 17.5803L34.6066 14L36.6975 15.2421C35.3143 18.2151 35.7984 18.9607 35.8036 18.9668C42.1834 19.1134 45.9029 24.3711 43.7593 30.0525C56.6009 34.9891 61.1391 47.8898 55.75 60H52.9707C55.3781 55.9175 56.0232 50.8217 55.2433 46.2103C48.7612 55.878 39.9517 39.8281 22.6597 54.3239L24.5839 48.233L20.5311 52.5729C21.017 55.3156 22.1239 57.8435 23.7034 60H20.7677C19.7717 58.3617 19.0128 56.588 18.514 54.7328L16.0936 57.3246C15.4027 44.3347 18.4093 35.3609 29.3749 30.4251ZM34.3914 54.9531C34.3914 53.3234 32.6322 52.2994 31.2323 53.1142C29.8324 53.929 29.8324 55.9772 31.2323 56.792C32.6322 57.6067 34.3914 56.5827 34.3914 54.9531ZM45.6399 54.9531C45.6399 53.3234 43.8806 52.2994 42.4807 53.1142C41.0809 53.929 41.0809 55.9772 42.4807 56.792C43.8805 57.6067 45.6399 56.5827 45.6399 54.9531ZM30.4319 20.2489C29.8851 21.2463 29.4305 22.2465 29.102 23.2734L26.7048 23.1236C26.222 23.0938 25.8527 22.6729 25.8808 22.1855L26.0457 19.4966C26.0746 19.0272 26.4602 18.664 26.9237 18.664C26.9412 18.664 29.6388 18.832 29.6388 18.832C30.3718 18.9493 30.5609 19.4164 30.4319 20.2489ZM36.9416 30.4423L43.8316 39.0044C44.4431 39.7612 43.3241 40.6831 42.7125 39.9263L42.0265 39.0723L39.1708 41.4191L37.3558 39.1847L40.2232 36.8281L39.2229 35.5832L37.8461 36.7239L36.923 35.545L38.2854 34.4162L35.8227 31.3642C35.2345 30.6365 36.3535 29.7147 36.9416 30.4423Z" fill="var(--color2)"/></svg>
                </a>

                <a href="https://cyb.ai/network/bostrom/contract/bostrom1ndwqfv2skglrmsqu4wlneepthyrquf9r7sx6r0" target="_blank" rel="noopener nofollow">
                    <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="37" cy="37" r="37" fill="currentColor"/><circle cx="37" cy="37" r="18" fill="var(--color2)"/></svg>
                </a>
            </div>

            <div class="line"></div>

            <div class="copyright">
                {{ $t('message.footer_copyright') }}
            </div>
        </div>
    </footer>
</template>


<style scoped>
    footer
    {
        width: 1440px;
        max-width: 100%;
        margin: 0 auto;
        padding: 24px 0;

        border: 1px solid #7544ff;
        border-bottom: none;
        border-radius: 48px 48px 0 0;
        background: radial-gradient(77.98% 100% at 50% 0%, rgba(65, 25, 131, .70) 0%, rgba(34, 27, 52, .70) 50%, rgba(0, 0, 0, .70) 100%);
    }



    .title
    {
        font-size: var(--font_size_title);
        font-weight: 500;
        line-height: 130%;

        letter-spacing: -1.2px;
    }



    .socials
    {
        display: flex;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;

        margin-left: auto;
    }


    .socials a
    {
        display: block;

        text-decoration: none;

        color: currentColor;
        border-radius: 50%;
        transition: opacity .2s linear;
    }

    .socials a + a
    {
        margin-left: 16px;
    }


    .socials a:hover{
        opacity: 0.7;
    }


    .socials svg
    {
        display: block;

        width: 36px;
        height: 36px;

        border-radius: 50%;

        --color2: #000;
    }



    .line
    {
        width: 1308px;
        max-width: 100%;
        height: 1px;
        margin-top: 16px;

        background: linear-gradient(90deg, rgba(255, 255, 255, .00) 0%, rgba(255, 255, 255, .20) 50.04%, rgba(255, 255, 255, .00) 100%);
    }



    .copyright
    {
        font-weight: 500;
        line-height: 130%;

        width: 100%;
        margin-top: 16px;

        text-align: center;
        letter-spacing: -.64px;

        opacity: .7;
        color: #fff;
    }
</style>