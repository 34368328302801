const network = {
    pretty_name: 'Space pussy',

    chain_id: 'space-pussy',
    bech32_prefix: 'pussy',

    images: [{
        png: '/space_pussy_logo.png'
    }]
}

export default network